import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin} from "@fortawesome/free-brands-svg-icons";
import logos from "../../Assets/logos.png";

import {FaEnvelope} from 'react-icons/fa';

import "./footer.css";

const Footers = () => {
  const [display, setDisplay] = useState("none");

  const listenScrollEvent = () => {
    window.scrollY > 90 ? setDisplay("inline") : setDisplay("none");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <footer
      className="footer"
      id="footerId"
      style={{
        display: display,
        transition: "all 1s",
      }}
    >
      <div className="footer-info">

           #1008 3601, Highway 7 E, Markham, ON{'\n'}
          Phone: 905-948-8637{'\n'}
            Fax: (905) 948-8638
    
      </div>
      <div className="social-container">
        <ul className="icons">
          <li className="ico">
            <a
              href="https://www.linkedin.com/company/wasserman-ramsay-chartered-accountants/"
              className="social"
            >
              <FontAwesomeIcon className="fa" icon={faLinkedin} />
            </a>
          </li>
           <li className="ico">
            <a
              href="mailto:wram@wassermanramsay.ca"
              className="social"
            >
              <FaEnvelope className = 'fa' />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footers;
