import React, {useState} from "react";
import "./home.css";
import { motion } from "framer-motion";
import About from '../about/about.js'
import DropboxChooser from "react-dropbox-chooser";

const APP_KEY = "p4vz7uflm353tuj";

const Home = () => {
  const [url, setUrl] = useState("");
  function handleSuccess(files) {
    console.log(files[0])
    setUrl(files[0].icon);
    console.log(url);
  }

  const variants = {
    initial: {
      opacity: 0,
      y: 8,
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: [0.61, 1, 0.88, 1],
      },
    },
  };
  return (
    <motion.div initial="initial" animate="enter" variants={variants}>
      <div className = 'hmPage'>
      <div className = 'hmC'>
          <p className = 'wrt'>
            WASSERMAN RAMSAY CHARTERED PROFESSIONAL ACCOUNTANTS
          </p>
          <p className = 'wrs'>
            Accounting for Every Business
          </p>
          </div>
      </div>
      <About/>
    </motion.div>
  );
};

export default Home;
