import React from "react";
import "./services.css";

import { FaRegListAlt, FaBook, FaMoneyBill, FaChartLine, FaCalendarAlt, FaClipboardList} from 'react-icons/fa';



const Services = () => {
  return (
    <div className ='servBox' >

      <div className="content">

      <div class="card">
            <div class="icon"><FaRegListAlt size={28}/></div>
            <p class="title">Audit</p>
            <p class="text"> Ensuring that financial statements are materially compliant with
              financial reporting frameworks.</p>
         
      </div>
      <div class="card">
         
            <div class="icon"><FaBook size={28}/></div>
            <p class="title">Bookkeeping</p>
            <p class="text">  Supporting the maintenance and accuracy of accounts payable and
              receivables, and payroll</p>
         
      </div>

      <div class="card">
         
            <div class="icon"><FaMoneyBill size={28}/></div>
            <p class="title">Financial Advisory</p>
            <p class="text">Providing expert advice on personal finances, investments and tax
              strategies to help achieve financial
              goals.</p>
         
      </div>

        <div class="card">
         
            <div class="icon"><FaCalendarAlt size={28}/></div>
            <p class="title">Tax Planning</p>
            <p class="text">Creating tax efficient plans to minimize tax payments while
              ensuring regulation compliance, optimizing long-term tax savings.</p>
         
      </div>
        <div class="card">
         
            <div class="icon"><FaChartLine size={28}/></div>
            <p class="title">Forecasting</p>
            <p class="text"> Providing accurate forecasts of business-relevant financial
              metrics, in order to guide business decisions.</p>
         
      </div>
       <div class="card">
         
            <div class="icon"><FaClipboardList size={28}/></div>
            <p class="title">Personal Tax Returns</p>
            <p class="text">  Assistance with filing, managing and reporting personal taxes.</p>
         
      </div>
   

   
   </div>
    </div>
  );
};

export default Services;
